import caseHeader from '../assets/img/themes/scarlett/folder_header.png';
import addElement from "../assets/img/themes/irbisPro/add_element.png";
import investigationCover from "../assets/img/case_cover.png";


export const scarlettTheme = {
  main_bg: '#313445',
  header_bg: '#EB8059',
  header_shadow: '#EB8059',
  header_menu: '#FFF',
  header_menu_active: '#525D74',
  header_user: '#FFFFFF',
  header_user_avatar: '#8A9CAB',
  header_user_avatar_bg: '#FFF',
  footer_color: '#FFF',
  sider_bg: '#525D74',
  sider_tree_color: '#FFF',
  sider_tree_selected_color: '#EB8059',
  sider_tree_selected_bg: '#F3F9FE',
  tree_icon_investigation: 'investigation_orange',
  tree_icon_case: 'case_orange',
  tree_icon_profiler: 'profiler_orange',
  tree_icon_webint: 'webint_orange',
  case_header: caseHeader,
  case_bg: '#e2e8f0',
  case_border: 'rgba(82,93,116,0.47)',
  case_title_color: '#EB8059',
  case_subtitle_color: '#2E3A59',
  investigation_cover: investigationCover,
  add_element: addElement,
  add_element_title: `
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  add_element_container: `
    border-radius: 10px;
    border: 1px dashed #B6BFCE;
    background: none;
  `,
  target_element_border: '10px',
  grid_switcher_bg: '#525D74',
  grid_switcher_border: '1px solid #525D74',
  grid_switcher_highlight: '#EB8059',
  grid_switcher_color: '#FFF',
  grid_switcher_inactive_color: '#9ba2aa',
  grid_widget_bg: '#e2e8f0',
  grid_widget_shadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.22);',
  grid_widget_border: 'none',
  grid_widget_border_radius: '15px',
  grid_widget_margin: [25, 25],
  grid_widget_row_height: 15,
  grid_header_bg: '#e2e8f0',
  grid_header_color: '#03173D',
  grid_header_border: 'none',
  grid_resizer_position: `bottom: 10px; right: 10px;`,
  button_border: '#EB8059',
  button_border_radius: '5px',
  button_bg: '#EB8059',
  button_hover: '#924D34',
  button_active: '#924D34',
  button_text_bg: '#FFF',
  modal_bg: '#dcdcdc',
  modal_border_radius: '15px',
  modal_title_color: '#EB8059',
  content_title_color: '#EB8059',
  input_border_radius: '5px',
  input_group_gap: '3px',
  post_line_bg: '#ecd1c6',
  post_line_border_radius: '0',
  tabs_bg: '#8B929D',
  tabs_border_radius: '5px',
  tabs_active_bg: '#525D74',
  tabs_active_color: '#F8F9FA',
  login_border: 'none',
  login_bg: '#EB8059',
  login_border_radius: '10px',
  login_box_shadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.22)',
  login_input_border: '1px solid #A3C4DF',
  login_input_border_radius: '10px',
}