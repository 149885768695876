import React from 'react';
import {Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {withTranslation} from "react-i18next";
import {SupportService} from "../../../../support";
import {ImageRender} from "../../Images";

const ImageSearchResults = ({t, data}) => {
  const {imageSearchResults = {}} = data;
  const {imageResults = []} = imageSearchResults;
  //console.log('isr,', imageResults);

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Image Search results")}</Text>


      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
        {
          imageResults.map((res, idx) =>
            <View key={idx} style={{width: '150px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
              <View>
                {ImageRender(res.avatar)}
              </View>
              <View>
                <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont', width: '80px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{res.name}</Text>
                {
                  !!res?.location &&
                  <Text style={{fontSize:'8px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res.location}</Text>
                }
                <Text style={{fontSize:'8px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{SupportService.getRoundNumber(res?.confidence)}%</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <Link src={res?.profile ? res?.profile : `${window?.location?.origin}${res?.profileUrl}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <SocialIcon network={res?.profiles?.[0]?.sn} />
                    <Text style={{fontSize: '10px', fontFamily: 'pdfFont', width: '80px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{res?.profiles?.[0]?.name}</Text>
                  </Link>
                </View>
              </View>
            </View>
          )
        }
      </View>
    </View>
  );
};

export default withTranslation()(ImageSearchResults);