import {defaultTheme} from "../themes/default";
import {regularTheme} from "../themes/regular";
import {oldWicenterTheme} from "../themes/old-wicenter";
import {irbisProTheme} from "../themes/irbispro";
import {scarlettTheme} from "../themes/scarlett";

export const themes = {
  //initial theme
  regular: regularTheme,
  //default from config
  default: defaultTheme,
  //oldWicenter
  oldWicenter: oldWicenterTheme,
  //Irbis Pro
  irbisPro: irbisProTheme,
  //scarlett
  scarlett: scarlettTheme,
}