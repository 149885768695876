import BaseReducer from "../BaseReducer";
import ProfilerAction from "./profilerAction";

export default class ProfilerReducer extends BaseReducer {
  initialState = {
    personLoading: false,
    person: {},
    personalInfo: {},
    locations: [],
    friends: [],
    friendsStat: {},
    images: [],
    webSearch: [],
    posts: [],
    preferences: [],
    contactInfo: [],
    synopsis: '',
    generalStat: {},
    postStat: {},
    psychoProfile: {},
    searchProgress: {},
    imageSearchResults: {},
    exportLoading: false,
    isNotFound: false,
    silentLoading: false,
  };

  /* SET ANY PROPERTY TO Profiler store*/
  [ProfilerAction.UPDATE_PROFILER_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    }
  }


  [ProfilerAction.SET_PERSON](state, action) {
    return {
      ...state,
      person: action.payload,
    };
  }
  [ProfilerAction.SET_PERSON_LOADING](state, action) {
    return {
      ...state,
      personLoading: action.payload,
    };
  }

  [ProfilerAction.UPDATE_PERSONAL_INFO](state, action) {
    const {field, value} = action.payload;
    return {
      ...state,
      personalInfo: {...state.personalInfo, [field]: [...state.personalInfo?.[field] || [], value], }
    };
  }

  [ProfilerAction.UPDATE_SOURCES](state, action) {
    return {
      ...state,
      searchProgress: {...state.searchProgress, socialCrawlers: [...state.searchProgress.socialCrawlers, action.payload], }
    };
  }
}
