import React from "react";
import {Spin} from "antd";

const styles = {
  background: 'rgba(0, 0, 0, 0.05)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //height: '90.2vh',
  height: '100%',
  width: '100%',
}

const Loading = ({style, spinner = false}) => {
  return (
    <div style={{...styles, ...style}}>
      {spinner ? <img src={spinner} /> : <Spin />}
    </div>
  );
};

export default Loading;