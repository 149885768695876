import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'pdfFont',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'pdfFont',
    marginBottom: 10,
  },
  phone: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
    fontFamily: 'pdfFont'
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  sourceText: {
    margin: 5,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'pdfFont',
  },
  row: {
    margin: "auto",
    flexDirection: "row",
    textAlign: 'left',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    'object-fit': 'cover',
  },
  header: {
    fontSize: 10,
    fontFamily: 'pdfFont',
    marginBottom: 20,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#3994DB',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 40,
    padding: 13
  },
  headerPadding: {
    height: 20,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  link: {
    textDecoration: 'none',
    verticalAlign: 'middle',
    fontSize: 12,
  },
  sourceTitle: {
    fontWeight: 600,
    color: '#666',
    padding: '0.5em 1.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 0.5,
    marginTop: 15,
    marginBottom: 10,
  },
  sourceSubTitle: {
    fontSize: 12,
    fontWeight: 300,
    color: '#666',
    padding: '0.5em 1.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 0.5,
    marginTop: 15,
    marginBottom: 10,
  },
  column50: {
    width: '50%'
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  sourceImage: {
    width: 100,
    height: 100,
    objectFit: 'cover',
    marginRight: 3,
    marginBottom: 3
  }
});