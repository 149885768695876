import moment from "moment";

export const transformAvatarSomeData = (info) => {
  return info;
};

export const transformToUpdateActivity = (
  elementToUpdate,
  idActivity
) => {
  return {
    ...elementToUpdate,
    id: idActivity
  }
};

export const transformUsage = (reqData) => {
  const finalObject = {
    profiler: 0,
    webint: 0,
    chartData: [],
    avatars: [],
  };
  if (reqData.length === 0) {
    return finalObject;
  }
  finalObject.profiler = reqData.map((el) => {
    return {
      username: el.avatarDto.username,
      value: el.moduleUsage.PROFILER || 0,
    };
  });

  finalObject.webint = reqData.map((el) => {
    return {
      username: el.avatarDto.username,
      value: el.moduleUsage.WEBINT || 0,
    };
  });

  const chartDataArray = [];
  reqData.forEach((elFull) => {
    Object.keys(elFull.dailyUsage).forEach((key) => {
      const chartArrayElem = {
        fieldName: elFull.avatarDto.username,
        date: moment(key, "YYYY-MM-DD").format("DD-MM-YYYY"),
        value: elFull.dailyUsage[key],
      };
      chartDataArray.push(chartArrayElem);
    });
  });
  //console.log('chartsData array', chartDataArray);

  //finalObject.chartData = chartDataArray;
  /*finalObject.chartData = chartDataArray.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );*/

  //normalize series:
  const dateVariants = [...new Set(chartDataArray.map(tag => tag.date))].sort(
    (a, b) => moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY")
  );
  //console.log('dateVariants', dateVariants);
  const normalizedDateVariants = [];
  const normalizedChartData = [];
  //const startDate = dateVariants[0];
  const startDate = moment().add(-6, 'months').format("DD-MM-YYYY");
  const endDate = dateVariants[dateVariants.length - 1];
  //console.log('s-e', dateVariants, startDate, endDate);

  let iterationDate = startDate;
  while (iterationDate !== endDate) {
    normalizedDateVariants.push(iterationDate);
    iterationDate = moment(iterationDate, "DD-MM-YYYY").add(1, 'day').format("DD-MM-YYYY");
  }
  const userVariants = [...new Set(reqData.map((el) => el.avatarDto.username))];

  normalizedDateVariants.forEach(day => {
    //console.log('day', day, userVariants);
    userVariants.forEach(user => {
      const found = chartDataArray.find(el => el.date === day && el.fieldName === user);
      if (found) {
        normalizedChartData.push(found);
      } else {
        normalizedChartData.push({
          fieldName: user,
          date: day,
          value: null
        });
      }
    })
  });
  finalObject.chartData = normalizedChartData;


  //console.log('finalObject.chartData', finalObject.chartData);
  //console.log('normalizedDateVariants', normalizedDateVariants);
  //console.log('userVariants', userVariants);
  //console.log('normalizedChartData', normalizedChartData);

  finalObject.avatars = reqData.map((el) => {
    return {
      sn: el.avatarDto.domain,
      username: el.avatarDto.username,
    };
  });
  return finalObject;
};

export const transformAIPosts = (aiPosts = []) => {
  const outData = [];
  let randDates = [];

  aiPosts.forEach((aiPost, idx) => {
    randDates.push(
      moment(new Date(+(new Date()) + Math.floor(Math.random()*1000000000)))
    )
  })
  randDates = randDates.sort((a, b) => a > b ? 1 : -1)
  console.log('randDates', randDates);

  aiPosts.forEach((aiPost, idx) => {
    outData.push({
      id: idx,
      date: randDates[idx].format("DD-MM-YYYY"),
      files: null,
      text: aiPost.content,
      time: randDates[idx],
      topic: aiPost.topic,
      sentiment: aiPost.sentiment,
    })
  })
  console.log('preparedPosts', outData)
  return outData;
};
